import React from 'react';
import ReactTable, { Column } from 'react-table';
import { Button } from 'reactstrap';
import "react-table/react-table.css";

import {
  Delivery,
} from '../../Types';

export type DeliveriesTableProps = {
  deliveries: Delivery[];
  deliveryInModal?: Delivery;
  initForm?: (d: Delivery) => void;
  toggleDeliveryModal?: (d: Delivery | null, keepOpen?: boolean) => void;
}

const dataColumns: Column[] = [
  {
    Header: 'Recipient',
    columns: [
      {
        Header: 'Last Name',
        id: 'lastName',
        accessor: (d: Delivery) => {
          if (d.recipient) {
            return (d.recipient.lName || d.recipient.dept && `(${d.recipient.dept})` || '-');
          } else {
          }
        },
      },
      {
        Header: 'First Name',
        id: 'firstName',
        accessor: (d: Delivery) => {
          console.log(d);
          
          if (d.recipient) {
            return (d.recipient.fName || d.recipient.dept && `(${d.recipient.dept})` || '-');
          } else {
          }
        }
      },
    ]
  },
  {
    Header: 'Packages',
    columns: [
      {
        Header: 'Barcodes',
        id: 'barcodes',
        accessor: (d: Delivery) => d.barcodes.join(', '),
      },
      {
        Header: 'Date Received',
        id: 'dateReceived',
        accessor: 'dateReceived',
        // accessor: (d: Delivery) => (new Date(d.dateReceived)).toLocaleDateString('en-US', { timeZone: 'America/New_York' }),
        Cell: row => new Date(row.row.dateReceived).toLocaleDateString('en-US', { timeZone: 'America/New_York' }),
        sortMethod: (a: string, b: string) => a.localeCompare(b),
      },
    ]
  },
]

const DeliveriesTable = (props: DeliveriesTableProps) => {
  const {
    deliveries,
    deliveryInModal,
    initForm,
    toggleDeliveryModal,
  } = props;
  console.log(deliveries);
  
  const defaultPageSize = 10;
  const actionColumns: Column = {
    Header: 'Actions',
    columns: [
      {
        Header: 'Options',
        id: 'Options',
        style: { textAlign: 'center' },
        Cell: ({ original }: { original: Delivery }) => (
          <div>
            {initForm ? <Button
              onClick={() => initForm(original)}
              color="info"
            >
              Edit
            </Button> : null}
            {toggleDeliveryModal ? <Button
              style={{ marginLeft: '5px' }}
              onClick={() => toggleDeliveryModal(original, deliveryInModal ? deliveryInModal.id !== original.id : undefined)}
              color="info"
            >
              View
            </Button> : null}
          </div>
        )
      }
    ],
  };

  return <ReactTable
    style={{ fontSize: '.85rem' }}
    data={deliveries}
    columns={dataColumns.concat(initForm || toggleDeliveryModal ? actionColumns : [])}
    defaultPageSize={defaultPageSize}
    defaultSorted = {[
      {
        id: "dateReceived",
        desc: true
      }
    ]}
  />
}

export default DeliveriesTable;
