import React, { Component } from "react";
import {
  Button,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { Recipient, Delivery } from "../../Types";

export type ModalProps = {
  isOpen: boolean;
  onClose: (d: Delivery | null) => void;
  delivery: Delivery;
};

class DeliveryModal extends Component<ModalProps, {}> {
  render() {
    const { isOpen, onClose, delivery } = this.props;

    return (
      <div className="deliveryLookupModal">
        <Modal
          isOpen={isOpen}
          backdrop={false}
          wrapClassName={"deliveryLookupModal"}
          modalClassName={"deliveryLookupModal"}
          backdropClassName={"deliveryLookupModal"}
          contentClassName={"deliveryLookupModal"}
        >
          <ModalHeader>
            Package Tracking: {delivery.barcodes.join(", ")}
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col>
                <table>
                  <tbody>
                    <tr>
                      <th>Date Received</th>
                      <td>
                        {new Date(delivery.dateReceived).toLocaleDateString(
                          "en-US",
                          { timeZone: "America/New_York" }
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th>Note</th>
                      <td>{delivery.note}</td>
                    </tr>
                    <tr>
                      <th>Recipient</th>
                      <td>
                        {delivery.recipient.fName} {delivery.recipient.lName}
                      </td>
                    </tr>
                    <tr>
                      <th>Recipient ID</th>
                      <td>{delivery.recipient.empId}</td>
                    </tr>
                    <tr>
                      <th>Building</th>
                      <td>{delivery.recipient.bldg}</td>
                    </tr>
                    <tr>
                      <th>Dept</th>
                      <td>{delivery.recipient.dept}</td>
                    </tr>
                    <tr>
                      <th>Room</th>
                      <td>{delivery.recipient.room}</td>
                    </tr>
                    <tr>
                      <th>Phone</th>
                      <td>{delivery.recipient.workPhone}</td>
                    </tr>
                    <tr>
                      <th>Email</th>
                      <td>{delivery.recipient.email}</td>
                    </tr>
                    <tr>
                      <th>Undeliverable</th>
                      <td>{delivery.undeliverable ? "Yes" : "No"}</td>
                    </tr>
                  </tbody>
                </table>
              </Col>
            </Row>
          </ModalBody>

          <ModalFooter>
            <Button
              style={{
                marginRight: ".25vw",
              }}
              color={"secondary"}
              onClick={() => onClose(null)}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default DeliveryModal;
