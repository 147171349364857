/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateDelivery = /* GraphQL */ `
  subscription OnCreateDelivery($id: ID) {
    onCreateDelivery(id: $id) {
      id
      barcodes
      undeliverable
      dateReceived
      note
      department
      createdBy
      modifiedBy
      dateCreated
      dateModified
      createdBySub
      modifiedBySub
      recipient {
        id
        empId
        lName
        fName
        init
        bldg
        room
        workPhone
        email
        dept
        _dateModified
      }
    }
  }
`;
export const onUpdateDelivery = /* GraphQL */ `
  subscription OnUpdateDelivery($id: ID) {
    onUpdateDelivery(id: $id) {
      id
      barcodes
      undeliverable
      dateReceived
      note
      department
      createdBy
      modifiedBy
      dateCreated
      dateModified
      createdBySub
      modifiedBySub
      recipient {
        id
        empId
        lName
        fName
        init
        bldg
        room
        workPhone
        email
        dept
        _dateModified
      }
    }
  }
`;
export const onDeleteDelivery = /* GraphQL */ `
  subscription OnDeleteDelivery($id: ID) {
    onDeleteDelivery(id: $id) {
      id
      barcodes
      undeliverable
      dateReceived
      note
      department
      createdBy
      modifiedBy
      dateCreated
      dateModified
      createdBySub
      modifiedBySub
      recipient {
        id
        empId
        lName
        fName
        init
        bldg
        room
        workPhone
        email
        dept
        _dateModified
      }
    }
  }
`;
