/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getDelivery = /* GraphQL */ `
  query GetDelivery($ids: [ID]!) {
    getDelivery(ids: $ids) {
      id
      barcodes
      undeliverable
      dateReceived
      note
      department
      createdBy
      modifiedBy
      dateCreated
      dateModified
      createdBySub
      modifiedBySub
      recipient {
        id
        empId
        lName
        fName
        init
        bldg
        room
        workPhone
        email
        dept
        _dateModified
      }
    }
  }
`;
export const listDelivery = /* GraphQL */ `
  query ListDelivery(
    $filter: TableDeliveryFilterInput
    $limit: Int
    $order: TableDeliveryOrderInput
    $nextToken: String
  ) {
    listDelivery(
      filter: $filter
      limit: $limit
      order: $order
      nextToken: $nextToken
    ) {
      items {
        id
        barcodes
        undeliverable
        dateReceived
        note
        department
        createdBy
        modifiedBy
        dateCreated
        dateModified
        createdBySub
        modifiedBySub
      }
      nextToken
    }
  }
`;
export const listArchiveDelivery = /* GraphQL */ `
  query ListArchiveDelivery($beforeDate: AWSDateTime, $afterDate: AWSDateTime) {
    listArchiveDelivery(beforeDate: $beforeDate, afterDate: $afterDate) {
      id
      barcodes
      undeliverable
      dateReceived
      note
      department
      createdBy
      modifiedBy
      dateCreated
      dateModified
      createdBySub
      modifiedBySub
      recipient {
        id
        empId
        lName
        fName
        init
        bldg
        room
        workPhone
        email
        dept
        _dateModified
      }
    }
  }
`;
