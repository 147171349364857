export { objSchemaMap } from './objSchemaMap';

export type Recipient = {
  id: string;
  empId: string;
  lName: string;
  fName: string;
  init: string;
  bldg: string;
  room: string;
  workPhone: string;
  email: string;
  dept: string;
}
export type Delivery = {
  id: string;
  note: string;
  barcodes: string[];
  department: string;
  undeliverable: Boolean;
  dateReceived: string;
  createdBy: string,
  modifiedBy: string;
  dateCreated: string;
  dateModified: string;
  createdBySub: string;
  modifiedBySub: string;
  recipient: Recipient;
}

export type User = {
  // primary properties
  username?: string;
  sub?: string;
  email?: string;
  groups?: string[];
  isDev?: boolean;
  isAdmin?: boolean;
  department?: string;
  role?: string;

  // additional user-maintenance attribrutes; will not be available on currentUser
  firstName?: string;
  lastName?: string;
  enabled?: boolean;
  phoneNumber?: string;
}

export type CurrentAuth = {
  user: User;
  credentials: any;
}

export enum Department {
  mailroom = 'mailroom',
  receiving = 'receiving',
  all = 'all',
}

export enum Role {
  admin = 'admin',
  dev = 'dev',
  user = 'user',
}
