import { Auth } from 'aws-amplify';
import { CognitoIdentityServiceProvider } from 'aws-sdk';
// sagas
import { put, all, select, takeEvery } from 'redux-saga/effects';
// redux
import { setUsers, mergeUser, removeUser } from '../actions';
import {
  POPULATE_USERS,
  PUT_USER,
  DELETE_USER,
  RESET_USER_PASSWORD,
} from '../constants';
// misc
import { User } from '../../Types';
import awsmobile from './../../aws-exports';
import { Role, Department } from '../../data';
import {
  getAttributeMap,
  createUserAttrs,
  haveUserAttrsChanged,
  takeFirst,
  isTestEnv,
} from '../../utils';

const GROUPS = [
  ...Object.values(Role),
  ...Object.values(Department),
];
const depList = Object.values(Department);

const populateUsersSaga = function* (action) {
  try {
    const UserPoolId = awsmobile.aws_user_pools_id;
    const auth = yield Auth.currentCredentials();

    const service = new CognitoIdentityServiceProvider({
      region: awsmobile.aws_cognito_region,
      credentials: auth,
    });
    const groupLists = yield all(GROUPS.map(group => service.listUsersInGroup({
      UserPoolId,
      GroupName: group,
    }).promise()));

    const users = groupLists.reduce((users: User[], group: CognitoIdentityServiceProvider.ListUsersInGroupResponse, index: number) => {
      (group.Users || []).forEach((user: CognitoIdentityServiceProvider.UserType) => {
        const userAttr = getAttributeMap(user);
        const groupName = GROUPS[index];
        const temp: User | undefined = users.find(u => u.sub === userAttr.sub);
        const existingUser: User = temp || {
          username: user.Username,
          sub: userAttr.sub,
          email: userAttr.email,
          enabled: user.Enabled,
          phoneNumber: userAttr.phone_number,
          firstName: userAttr.given_name,
          lastName: userAttr.family_name,
        };


        if (groupName === Role.admin) {
          existingUser.role = groupName;
          existingUser.isAdmin = true;
        }

        if (groupName === Role.user) {
          existingUser.role = groupName;
        }

        if (groupName === Role.dev) {
          existingUser.isDev = true;
        }

        if (depList.includes(groupName as Department)) {
          existingUser.department = groupName;
        }

        if (!temp) {
          users.push(existingUser);
        }

        return users;
      });
      return users;
    }, [] as User[]);

    yield put(setUsers(users));
  } catch (err) {
    console.log('populateUsers err', err);
  }
};

const resetUserPasswordSaga = function* (action) {
  const userToReset: User = action.payload;
  const currentUser = yield select((state: any) => state.auth.currentUser);
  const auth = yield Auth.currentCredentials();
  const service = new CognitoIdentityServiceProvider({
    region: awsmobile.aws_cognito_region,
    credentials: auth,
  });

  if (
    currentUser.role === Role.admin
    && (currentUser.department === Department.all || currentUser.department === userToReset.department)
  ) {
    try {
      const result = yield service.adminResetUserPassword({
        UserPoolId: awsmobile.aws_user_pools_id,
        Username: userToReset.username as string,
      }).promise();

      console.log('resetUserPasswordSaga result', result);
    } catch(err) {
      console.log('resetUserPasswordSaga error', err);
    }

  } else {
    console.log('Unauthorized');
  }
}

const putUserSaga = function* (action) {
  try {
    const auth = yield Auth.currentCredentials();
    const service = new CognitoIdentityServiceProvider({
      region: awsmobile.aws_cognito_region,
      credentials: auth,
    });

    const user = action.payload;
    const previousIteration = user.sub && (
      yield select((state: any) => state.user.users.find((u: User) => u.sub === user.sub))
    );

    const UserPoolId = awsmobile.aws_user_pools_id;
    if (!previousIteration) {
      yield service.adminCreateUser({
        UserPoolId,
        Username: user.username,
        UserAttributes: createUserAttrs(user),
      }).promise();
      yield service.adminAddUserToGroup({
        UserPoolId,
        Username: user.username,
        GroupName: user.role,
      }).promise();
      yield service.adminAddUserToGroup({
        UserPoolId,
        Username: user.username,
        GroupName: user.department,
      }).promise();
    } else {
      if (haveUserAttrsChanged(user, previousIteration)) {
        yield service.adminUpdateUserAttributes({
            UserPoolId,
            Username: user.username,
            UserAttributes: createUserAttrs(user),
          }).promise();
      }
      if (previousIteration.enabled !== user.enabled) {
        const request: CognitoIdentityServiceProvider.AdminEnableUserRequest = {
          UserPoolId,
          Username: user.username
        };
        if (!user.enabled) {
          yield service.adminDisableUser(request).promise();
        } else {
          yield service.adminEnableUser(request).promise();
        }
      }
      if (previousIteration.role !== user.role) {
        if (!GROUPS.includes(user.role)) {
          throw new Error('invalidRole');
        }
        if (previousIteration.role) {
          yield service.adminRemoveUserFromGroup({
            UserPoolId,
            Username: previousIteration.username,
            GroupName: previousIteration.role,
          }).promise();
        }
        yield service.adminAddUserToGroup({
          UserPoolId,
          Username: previousIteration.username,
          GroupName: user.role,
        }).promise();
      }
      if (previousIteration.department !== user.department) {
        if (!GROUPS.includes(user.department)) {
          throw new Error('invalidRole');
        }
        if (previousIteration.department) {
          yield service.adminRemoveUserFromGroup({
            UserPoolId,
            Username: previousIteration.username,
            GroupName: previousIteration.department,
          }).promise();
        }
        yield service.adminAddUserToGroup({
          UserPoolId,
          Username: previousIteration.username,
          GroupName: user.department,
        }).promise();
      }
    }
    yield put(mergeUser(user));
  } catch (err) {
    console.log('putUserSaga err', err);
  }

};

const deleteUserSaga = function* (action) {
  try {
    const userToDelete = action.payload;
    const UserPoolId = awsmobile.aws_user_pools_id;
    const auth = yield Auth.currentCredentials();
    const currentUser = yield Auth.currentUserInfo();

    if (currentUser.username === userToDelete.username) {
      throw new Error(`Users cannot delete themselves.`);
    }

    const service = new CognitoIdentityServiceProvider({
      region: awsmobile.aws_cognito_region,
      credentials: auth,
    });
    yield service.adminDeleteUser({
      UserPoolId,
      Username: userToDelete.username,
    }).promise();
    yield put(removeUser(userToDelete.sub));
  } catch (err) {
    console.log('deleteUserSaga err', err);
  }

};

export default function* () {
  yield all([
    function* () { yield takeFirst(POPULATE_USERS, populateUsersSaga) }(),
    function* () { yield takeEvery(PUT_USER, putUserSaga) }(),
    function* () { yield takeEvery(DELETE_USER, deleteUserSaga) }(),
    function* () { yield takeEvery(RESET_USER_PASSWORD, resetUserPasswordSaga )}(),
  ])
};
