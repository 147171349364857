import {
  all,
  take,
  takeEvery,
  put,
  call,
  select,
} from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
// amplify
import { API, graphqlOperation, Auth } from 'aws-amplify';
// graphql
import {
  listDelivery,
  listArchiveDelivery,
} from '../../graphql/queries';
import {
  createDelivery,
  updateDelivery,
  deleteDelivery,
} from '../../graphql/mutations';
import {
  onCreateDelivery,
  onUpdateDelivery,
  onDeleteDelivery,
} from '../../graphql/subscriptions';
// redux
import {
  DELETE_DELIVERY,
  POPULATE_DELIVERIES,
  PUT_DELIVERY,
  SUBSCRIBE_TO_DELIVERIES,
  UNSUBSCRIBE_FROM_DELIVERIES,
  PUT_DELIVERY_AND_PRINT,
} from '../constants';
import {
  setDeliveries,
  mergeDelivery,
  mergeDeliveries,
  removeDelivery,
  print,
  setPrintValues,
} from '../actions';
import { User, Delivery } from '../../Types';
import { actions as toastrActions } from 'react-redux-toastr';
// misc
import uuid from 'uuid/v4';
import { setEndHour, sanitizeEmptyStrings, sanitizeGraphqlRes, dedup, nullToNull, backoffRetry } from '../../utils';
// types
import { ListDeliveryQueryVariables } from '../../API';

/*
    Subscriptions
*/
const subErrorCallback = (error) => {
  console.log('sub error:', error);
}
const deliverySubscriptions: any[] = [];
const createSubscriptionChannel = function () {
  const currentUser = JSON.parse(localStorage.getItem('currentUser') as any);
  const create: any = API.graphql(graphqlOperation(onCreateDelivery));
  const update: any = API.graphql(graphqlOperation(onUpdateDelivery));
  const remove: any = API.graphql(graphqlOperation(onDeleteDelivery));
  return eventChannel(emit => {
    deliverySubscriptions.push(create.subscribe({
      next: (delivery) => {
        const department = delivery.value.data.onCreateDelivery.department;
        if (currentUser.department === 'all' || currentUser.department == department) {
          return emit(mergeDelivery(sanitizeGraphqlRes(delivery.value.data.onCreateDelivery)));
        }
      },
      error: subErrorCallback,
    }));
    deliverySubscriptions.push(update.subscribe({
      next: (delivery) => {
        const department = delivery.value.data.onUpdateDelivery.department;
        if (currentUser.department === 'all' || currentUser.department == department) {
          return emit(mergeDelivery(sanitizeGraphqlRes(delivery.value.data.onUpdateDelivery)))
        }
      },
      error: subErrorCallback,
    }));
    deliverySubscriptions.push(remove.subscribe({
      next: (delivery) =>  emit(removeDelivery(sanitizeGraphqlRes(delivery.value.data.onDeleteDelivery))),
      error: subErrorCallback,
    }));
    return () => deliverySubscriptions.forEach(sub => sub.unsubscribe());
  });
}
const subscribeToDeliveriesSaga = function* () {
  const subscriptionChannel = yield call (createSubscriptionChannel);
  while (deliverySubscriptions.length) {
    const payload = yield take(subscriptionChannel);
    yield put(payload);
  }
}
const unsubscribeFromDeliveriesSaga = function* () {
  deliverySubscriptions.forEach(s => s.unsubscribe());
}

const errorToastAction = (title: string, message?: string) => toastrActions.add({
  message,
  title,
  type: 'error',
  position: 'top-center',
  attention: true,
  onAttentionClick: () => {},
  options: {
    attention: true,
    showProgressBar: false,
    closeOnToastrClick: false,
    showCloseButton: true,
  }
});
const successToastAction = (title: string, message?: string) => toastrActions.add({
  message,
  title,
  type: 'success',
  options: {
    showProgressBar: true,
    timeOut: 5000,
  }
});

export const listDeliveries = async (args: ListDeliveryQueryVariables) => {
  return await API.graphql(graphqlOperation(listDelivery, args))
}

export const listArchiveDeliveries = async (args: { beforeDate: string, afterDate: string }) => {

  console.log('args', args);
  try {
    const res =  await API.graphql(
      graphqlOperation(listArchiveDelivery, { beforeDate: args.beforeDate, afterDate: args.afterDate })
    );
    return res;
  } catch (err) {
    console.log('listArchiveDeliveries err', err);
  }
}

/*
  create/update
*/
export const populateDeliveriesSaga = function* (action) {
  const setQueryRunning = action.payload && action.payload.setQueryRunning || (() => void 0);
  const filter = action.payload && action.payload.filter || undefined;

  try {
    let nextToken;
    yield put(setDeliveries([]));
    setQueryRunning(true);
    console.log('filter', filter);
    while (nextToken !== null) {
      const deliveries = yield API.graphql(graphqlOperation(listDelivery, { limit: 1000, filter, nextToken }));
      nextToken = deliveries.data.listDelivery.nextToken;
      console.log('adding', deliveries.data.listDelivery.items.length, 'to store');
      yield put(mergeDeliveries(deliveries.data.listDelivery.items.map(nullToNull)));
    }
    setQueryRunning(false);
  } catch(err) {
    console.log('err', err);
    if (err.errors && err.errors.length && err.errors[0].message.includes('Communications link failure')) {
      yield put(errorToastAction(
        'Database is warming up after sleep',
        'Please try again in ~15s',
      ));
    } else {
      yield put(errorToastAction(
        'Error retrieving deliveries',
        'Please reload the page to refresh credentials.',
      ));
    }
  }
}

export const putDeliverySaga = function* (action) {
  const { payload } = action;
  const currentUser: User = yield select((state: any) => {
    return state.auth.currentUser;
  });

  let input;
  if (payload.id) {
    try {
      input = {
        ...payload,
        department: currentUser.department === 'all' ? 'mailroom' : currentUser.department,
        recipient: payload.recipient ? {
          ...payload.recipient,
          id: payload.recipient.id || uuid(),
        } : null,
      };
      yield backoffRetry(async () => {
        await API.graphql(graphqlOperation(updateDelivery, {
          input: sanitizeEmptyStrings(input),
        }));
      });
      yield put(successToastAction(
        'Delivery successfully saved.'
      ));
    } catch (err) {
      yield put(errorToastAction(
        'Error saving',
        'Please reload the page to refresh credentials.',
      ));
      console.log('updateDelivery err', err);
    }
  } else {
    input = {
      ...payload,
      id: uuid(),
      department: currentUser.department === 'all' ? 'mailroom' : currentUser.department,
      recipient: payload.recipient ? {
        ...payload.recipient,
        id: payload.recipient.id || uuid(),
      } : null,
    };
    try {
      yield backoffRetry(async () => {
        await API.graphql(graphqlOperation(createDelivery, {
          input: sanitizeEmptyStrings(input),
        }));
      });
      yield put (successToastAction(
        'Delivery successfully saved.'
      ));
    } catch (err) {
      yield put(errorToastAction(
        'Error saving',
        'Please reload the page to refresh credentials.',
      ));
      console.log('createDelivery err', err);
    }
  }
  if (action.type === PUT_DELIVERY_AND_PRINT) {
    yield put(print(input));
  }

  yield put(setPrintValues(input));
}

/*
  remove
*/
export const deleteDeliverySaga = function* (action) {
  const { payload } = action;
  try {
    yield API.graphql(graphqlOperation(deleteDelivery, { input: { id: payload.id } }));
    yield put(successToastAction('Delivery successfully deleted.'));
    yield put(removeDelivery(payload));
  } catch (err) {
    yield put(errorToastAction(
      'Error saving',
      'Please reload the page to refresh credentials.',
    ));
    console.log('deleteDelivery err', err);
  }
}

export default function* () {
  yield all([
    function* () { yield takeEvery(PUT_DELIVERY, putDeliverySaga) }(),
    function* () { yield takeEvery(DELETE_DELIVERY, deleteDeliverySaga) }(),
    function* () { yield takeEvery(POPULATE_DELIVERIES, populateDeliveriesSaga) }(),
    function* () { yield takeEvery(SUBSCRIBE_TO_DELIVERIES, subscribeToDeliveriesSaga) }(),
    function* () { yield takeEvery(UNSUBSCRIBE_FROM_DELIVERIES, unsubscribeFromDeliveriesSaga) }(),
    function* () { yield takeEvery(PUT_DELIVERY_AND_PRINT, putDeliverySaga) }(),
  ])
}
