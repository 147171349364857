import React, { Component } from 'react';
import { Button } from 'reactstrap';
import './SignedInHeader.css';
import { User, Department } from '../../Types';

export type SignedInHeaderProps = {
  override: any;
  authState: string;
  currentUser: User;
  nav: {
    users: () => void,
    archive: () => void,
    delivery: () => void,
    signOut: () => void,
  }
}

export const SignedInHeader = (props: SignedInHeaderProps) => {
  const { authState, nav, currentUser } = props;
  if (authState !== 'signedIn') {
    return null;
  }

  const isAdmin = currentUser ? currentUser.isAdmin : undefined;
  const groups = currentUser && currentUser.groups ? currentUser.groups : [];
  const username = currentUser && currentUser.username ? currentUser.username : '';

  const buttons = [
    { nav: nav.delivery, text: 'Delivery', className: 'delivery-button' },
    { nav: nav.users, admin: true, text: 'Users', className: 'users-button' },
    { nav: nav.archive, admin: true, text: 'Archive', className: 'archive-button' },
    { nav: nav.signOut, text: 'Sign Out', color: 'warning', className: 'sign-out-button' },
  ];

  const button_components = buttons.filter(but => isAdmin || !but.admin).map((but, key) => (<Button
    key={key}
    onClick={but.nav}
    className={but.className}
    color={but.color || 'info'}
  >
    {but.text}
  </Button>));

  return <div className="SignedInHeader">
    <div className="signedInCol">
      <div>{button_components}</div>
      <div className="">{username} - {groups.find(g => !!Department[g])}</div>
    </div>
  </div>
}
