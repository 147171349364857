// react
import React from 'react';
import ReactDOM from 'react-dom';
// global styles
import './index.css';
// amplify
import Amplify from 'aws-amplify';
import awsmobile from './aws-exports';
// components
import { App } from './containers';
// server
import * as serviceWorker from './serviceWorker';
// css
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'
import 'react-widgets/dist/css/react-widgets.css';

// configure amplify
Amplify.configure(awsmobile);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
