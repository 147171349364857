/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createDelivery = /* GraphQL */ `
  mutation CreateDelivery($input: CreateDeliveryInput!) {
    createDelivery(input: $input) {
      id
      barcodes
      undeliverable
      dateReceived
      note
      department
      createdBy
      modifiedBy
      dateCreated
      dateModified
      createdBySub
      modifiedBySub
      recipient {
        id
        empId
        lName
        fName
        init
        bldg
        room
        workPhone
        email
        dept
        _dateModified
      }
    }
  }
`;
export const updateDelivery = /* GraphQL */ `
  mutation UpdateDelivery($input: UpdateDeliveryInput!) {
    updateDelivery(input: $input) {
      id
      barcodes
      undeliverable
      dateReceived
      note
      department
      createdBy
      modifiedBy
      dateCreated
      dateModified
      createdBySub
      modifiedBySub
      recipient {
        id
        empId
        lName
        fName
        init
        bldg
        room
        workPhone
        email
        dept
        _dateModified
      }
    }
  }
`;
export const deleteDelivery = /* GraphQL */ `
  mutation DeleteDelivery($input: DeleteDeliveryInput!) {
    deleteDelivery(input: $input) {
      id
      barcodes
      undeliverable
      dateReceived
      note
      department
      createdBy
      modifiedBy
      dateCreated
      dateModified
      createdBySub
      modifiedBySub
      recipient {
        id
        empId
        lName
        fName
        init
        bldg
        room
        workPhone
        email
        dept
        _dateModified
      }
    }
  }
`;
