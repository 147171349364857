import {
  POPULATE_DELIVERIES,
  SET_DELIVERIES,
  PUT_DELIVERY,
  MERGE_DELIVERY,
  REMOVE_DELIVERY,
  DELETE_DELIVERY,
  SUBSCRIBE_TO_DELIVERIES,
  SET_RANGE,
  SET_VIEW_UNDELIVERABLE,
  TOGGLE_DELIVERY_MODAL,
  SET_TRACKING_SEARCH,
} from '../constants';
import { Delivery } from '../../Types';

/**
 * trigger call for deliveries to be retrieved remotely and set in the store
 */
export const populateDeliveries = (filter?: any, setQueryRunning?: (bool: boolean) => void) => ({
  payload: { filter, setQueryRunning },
  type: POPULATE_DELIVERIES,
});

/**
 * set deliviers in the store
 * @param deliveries
 */
export const setDeliveries = (deliveries: Delivery[]) => ({
  payload: deliveries,
  type: SET_DELIVERIES,
});

/**
 * trigger call for a delivery to be created/updated in the remote database, depending on whether or not an id is falsy/truthy
 * @param delivery
 */
export const putDelivery = (delivery: Delivery) => ({
  payload: delivery,
  type: PUT_DELIVERY,
});

/**
 * merge a delivery into deliveries of the store
 * @param delivery
 */
export const mergeDelivery = (delivery: Delivery) => ({
  payload: delivery,
  type: MERGE_DELIVERY,
});

export const mergeDeliveries = (deliveries: Delivery[]) => ({
  payload: deliveries,
  type: MERGE_DELIVERY,
})

/**
 * remove a delivery from deliveries in the store
 * @param delivery
 */
export const removeDelivery = (delivery: Delivery) => ({
  payload: delivery,
  type: REMOVE_DELIVERY,
});

/**
 * trigger call for delivery to be deleted from the remote database
 * @param delivery
 */
export const deleteDelivery = (delivery: Delivery) => ({
  payload: delivery,
  type: DELETE_DELIVERY,
});

export const subscribeToDeliveries = () => ({
  payload: null,
  type: SUBSCRIBE_TO_DELIVERIES,
});

export const setRange = (start: Date, end: Date) => ({
  payload: {
    start,
    end,
  },
  type: SET_RANGE,
});

export const setViewUndeliverable = (viewUndeliverable: boolean) => ({
  payload: viewUndeliverable,
  type: SET_VIEW_UNDELIVERABLE,
});

export const toggleDeliveryModal = (delivery: Delivery, keepOpen?: boolean) => ({
  payload: {
    delivery,
    keepOpen
  },
  type: TOGGLE_DELIVERY_MODAL,
});

export const setTrackingSearch = (trackingSearch: string) => ({
  payload: trackingSearch,
  type: SET_TRACKING_SEARCH,
});
